import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

const ShopPage = () => {

	const blockchain = useSelector((state) => state.blockchain);

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
	  const json = await response.json();
	  if (response.ok) {
		  if (json.length === 1) {
			// Expect only 1 result
			console.log(json[0]);
			setUserData(json[0]);
		  } else {
			console.log("Invalid user data: More than 1 result.");
		  }
	  }
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.rideChain !== null) {
			// getUserUSDTBalance();
			// checkTokenAllowance();
		}
	};

	useEffect(() => {
		getData();
		fetchUserData();
	}, []);

	return (
		<>
		<div className="main-content market-bg">
			<div className="container pt-5">
				
				<div className="row">
					<div className="col-12 text-center">
						<h3 className="brand-blue">我的商城钱包</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mt-2">
						{/* <div className="market-box"> */}
							<div className="wallet-balance-box text-left">
								<p className="text-white mb-0"><span className="brand-yellow">SRC:</span> {userData && parseFloat(userData.src || 0).toFixed(2)}</p>
							</div>
						{/* </div> */}
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">RC商城</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mt-2">
						<div className="data-box text-center">
							<p className="text-gray mb-0">即将上线</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);

};

export default ShopPage;