import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import MiddleEllipsis from "react-middle-ellipsis";
import Moment from 'moment';

import CustomBtn from '../assets/img/custom-btn.png';

// hooks
import { useOrder } from '../hooks/useOrder';

const MarketPage = () => {

	const { isOrdering, setIsOrdering, orderSuccess, setOrderSuccess, createSellOrder } = useOrder();

	const blockchain = useSelector((state) => state.blockchain);

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
	  const json = await response.json();
	  if (response.ok) {
		  if (json.length === 1) {
			// Expect only 1 result
			console.log(json[0]);
			setUserData(json[0]);
		  } else {
			console.log("Invalid user data: More than 1 result.");
		  }
	  }
	};

	const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
	  blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
	  .then((results) => {
		console.log("USDT Balance:", results);
		setUSDTBalance(results);
	  });
	};

	const [allowance, setAllowance] = useState(0);
	const checkTokenAllowance = () => {
	  blockchain.usdtContract.methods.allowance(blockchain.account, "0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4").call()
	  .then((results) => {
		console.log("Token Allowance:", results);
		setAllowance(results);
	  });
	};



	const [rcTradeAmount, setRcTradeAmount] = useState(0);
	const [tradeBalError, setTradeBalError] = useState('');
	const [invalidTradeAmount, setInvalidTradeAmount] = useState(true);
	const handleTradeAmount = (e) => {
	  	const value = e.target.value;
	  	console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidTradeAmount(true);
			setTradeBalError('请输入数字');
		} else if (e.target.value < 50) {
			setInvalidTradeAmount(true);
			setTradeBalError('最低数额: 50 RC');
		} else if (e.target.value % 10 != 0) {
			setInvalidTradeAmount(true);
			setTradeBalError('数额必须是 50 的倍数');
		} else if (userData.rc < e.target.value) {
			setInvalidTradeAmount(true);
			setTradeBalError('用户 RC 余额不足');
		} else {
			setInvalidTradeAmount(false);
			setTradeBalError('');
		}
		setRcTradeAmount(e.target.value);
	};


	const submitSellOrder = async () => {
		await createSellOrder(userData._id, blockchain.account, rcTradeAmount);
		setTimeout(() => getData(), 3000);
		setTimeout(() => fetchUserData(), 3000);
		setTimeout(() => fetchAllOrderData(), 4000);
	};

	const [rcSwapAmount, setRcSwapAmount] = useState(0);
	const [swapBalError, setSwapBalError] = useState('');
	const [invalidSwapAmount, setInvalidSwapAmount] = useState(true);
	const handleSwapAmount = (e) => {
		const value = e.target.value;
		console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidSwapAmount(true);
			setSwapBalError('请输入数字');
		} else if (e.target.value < 50) {
			setInvalidSwapAmount(true);
			setSwapBalError('最低数额: 50 RC');
		} else if (e.target.value % 10 != 0) {
			setInvalidSwapAmount(true);
			setSwapBalError('数额必须是 50 的倍数');
		} else if (userData.rc < e.target.value) {
			setInvalidSwapAmount(true);
			setSwapBalError('用户 RC 余额不足');
		} else {
			setInvalidSwapAmount(false);
			setSwapBalError('');
		}
		setRcSwapAmount(e.target.value);
  	};

	const [orderData, setOrderData] = useState(null);
	const fetchAllOrderData = async () => {
		const response = await fetch('https://api.ridechain.io/order');
		const json = await response.json();
		if (response.ok) {
			console.log("OTC Market Orders:", json);
			setOrderData(json);
		}
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.rideChain !== null) {
			getUserUSDTBalance();
			checkTokenAllowance();
		}
	};
	
	useEffect(() => {
		getData();
		fetchUserData();
		fetchAllOrderData();
		getUserUSDTBalance();
		checkTokenAllowance();
	}, []);

	return (
		<>
		<div className="main-content market-bg">
			<div className="container pt-5">
				<div className="row">
					<div className="col-12 text-center">
						<h3 className="brand-blue">我的钱包</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2">
						<div className="market-box">
							<div className="wallet-balance-box text-left">
								<p className="text-white mb-0"><span className="brand-yellow">USDT:</span> {parseFloat(usdtBalance/1000000000000000000).toFixed(2)}</p>
							</div>
							<div className="wallet-balance-box text-left mt-3">
								<p className="text-white mb-0"><span className="brand-yellow">RC:</span> {userData && parseFloat(userData.rc || 0).toFixed(2)}</p>
							</div>
							<div className="wallet-balance-box text-left mt-3">
								<p className="text-white mb-0"><span className="brand-yellow">WRC:</span> {userData && parseFloat(userData.wrc || 0).toFixed(2)}</p>
							</div>
							<div className="wallet-balance-box text-left mt-3">
								<p className="text-white mb-0"><span className="brand-yellow">SRC:</span> {userData && parseFloat(userData.src || 0).toFixed(2)}</p>
							</div>
							<div className="wallet-balance-box text-left mt-3">
								<p className="text-white mb-0"><span className="brand-yellow">RCT:</span> {userData && parseFloat(userData.rct || 0).toFixed(2)}</p>
							</div>
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">RC/WRC兑换</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2">
						<div className="market-box text-center">
							<p className="text-white mb-2" style={{fontSize:"16px"}}><span className="brand-yellow">RC钱包数额:</span> {userData && parseFloat(userData.rc || 0).toFixed(2)}</p>
							<input
								type="number"
								placeholder='请输入 RC 数额'
								name='swapAmount'
								className='form-control text-center'
								onChange={handleSwapAmount}
							/>
							{swapBalError != "" ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{swapBalError}</p> : <></>}
							<p className="text-white mt-2" style={{fontSize:"12px"}}>*用户把RC兑换成WRC将获得<span className="brand-green">+5%</span>WRC</p>
							<div className="w-100 text-center mt-5">
								<p className="error mb-0" style={{fontSize:"24px"}}><strong>-{parseFloat(Number(rcSwapAmount)).toFixed(2)} <span className="text-white">RC</span></strong></p>
								<div className="w-100 text-center my-3">
									<p className="brand-blue mb-0" style={{fontSize:"24px"}}><i className="fa-solid fa-circle-arrow-down"></i></p>
								</div>
								<p className="text-white mb-0" style={{fontSize:"12px"}}>WRC兑换: <span className="profit-green">+5%</span></p>
								<p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(Number(rcSwapAmount)+Number(rcSwapAmount*5/100)).toFixed(2)} <span className="text-white">WRC</span></strong></p>
								<button disabled className="btn custom-btn mt-3" data-dismiss="modal" onClick={(e) => {
								}}>
									<img className="w-100" src={CustomBtn}></img>
									<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>即将上线</strong></h5>
								</button>
								{/* <button disabled={swapBalError != ""} className="btn custom-btn mt-3" data-dismiss="modal" onClick={(e) => {
								}}>
									<img className="w-100" src={CustomBtn}></img>
									<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>确认兑换</strong></h5>
								</button> */}
							</div>
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">挂卖RC</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2">
						<div className="market-box text-center">
							{isOrdering ? (
								<div className="w-100 text-center mt-3">
									<h5 className="brand-yellow"><strong>正在挂卖 RC</strong></h5>
									<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>请稍等。。。</strong></p>
								</div>
							):(
								<div className="w-100 text-center mt-3">
									{!orderSuccess ? (
										<>
											<p className="text-white mb-2" style={{fontSize:"16px"}}><span className="brand-yellow">RC钱包数额:</span> {userData && parseFloat(userData.rc || 0).toFixed(2)}</p>
											<input
												type="number"
												placeholder='请输入 RC 数额'
												name='swapAmount'
												className='form-control text-center'
												onChange={handleTradeAmount}
											/>
											{tradeBalError != "" ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{tradeBalError}</p> : <></>}
											<p className="error mb-0 mt-5" style={{fontSize:"24px"}}><strong>-{parseFloat(Number(rcTradeAmount)).toFixed(2)} <span className="text-white">RC</span></strong></p>
											<div className="w-100 text-center my-3">
												<p className="brand-blue mb-0" style={{fontSize:"24px"}}><i className="fa-solid fa-circle-arrow-down"></i></p>
											</div>
											<p className="text-white mb-0" style={{fontSize:"12px"}}>手续费: <span className="brand-yellow">3%</span></p>
											<p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(rcTradeAmount-(rcTradeAmount*3/100)).toFixed(2)} <span className="text-white">USDT</span></strong></p>
											<button disabled className="btn custom-btn mt-3" data-dismiss="modal" onClick={(e) => {
											}}>
												<img className="w-100" src={CustomBtn}></img>
												<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>即将上线</strong></h5>
											</button>
											{/* <button disabled={invalidTradeAmount || tradeBalError != ""} className="btn custom-btn mt-3" data-dismiss="modal" onClick={(e) => {
												submitSellOrder();
											}}>
												<img className="w-100" src={CustomBtn}></img>
												<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>确认</strong></h5>
											</button> */}
										</>
									):(
										<>
											<h5 className="profit-green"><strong>成功挂卖RC!</strong></h5>
											<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>您的RC已经在OTC交易中心上挂卖。交易成交后USDT才会到账。</strong></p>
											<button className="btn custom-btn mt-3" data-dismiss="modal" onClick={(e) => {
												setOrderSuccess(false);
											}}>
												<img className="w-100" src={CustomBtn}></img>
												<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>完成</strong></h5>
											</button>
										</>
									)}
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">OTC交易中心</h3>
					</div>
				</div>

				<div className="row">
					{orderData && orderData.length > 0 ? (
						// <div className="col-12 mt-2">
						// 	{orderData && orderData.map((marketOrder, index) => (
						// 		<div className="data-box mt-2" key={index}>
						// 			{/* <p className="text-white mb-2" style={{fontSize:"14px"}}>{Moment(marketOrder.createdAt).format('YYYY/MM/DD')}</p> */}
						// 			<p className="brand-blue mb-0" style={{fontSize:"12px"}}><strong>卖家：</strong></p>
						// 			<MiddleEllipsis><span className="text-white mb-0" style={{fontSize:"12px"}}>{marketOrder.seller_wallet}</span></MiddleEllipsis>
						// 			<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{parseFloat(marketOrder.rc_amount).toFixed(2)} RC <i className="fa-solid fa-arrow-right mx-2"></i> {parseFloat(marketOrder.usdt_amount).toFixed(2)} USDT</strong></p>
						// 			{marketOrder.status == "Completed" ? (
						// 				<>
						// 					<p className="profit-green mb-0" style={{fontSize:"14px"}}><strong>已成交</strong></p>
						// 				</>
						// 			):(
						// 				<></>
						// 			)}
						// 			{marketOrder.status == "Processing" ? (
						// 				<button disabled className="btn btn-primary mt-2 mx-0" data-dismiss="modal" onClick={(e) => {
						// 				}}>
						// 					{/* <img className="w-100" src={CustomBtn}></img> */}
						// 					{/* <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>完成</strong></h5> */}
						// 					<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>购买</strong></p>
						// 				</button>
						// 			):(
						// 				<></>
						// 			)}
						// 			{/* {marketOrder.status == "Processing" ? <p className="brand-yellow mb-0" style={{fontSize:"14px"}}>正在处理中</p> : <></>} */}
						// 			{/* {marketOrder.status == "Completed" ? <p className="profit-green mb-0" style={{fontSize:"14px"}}>提现已到账</p> : <></>} */}
						// 		</div>
						// 	))}
						// </div>
						<div className="col-12 mt-2">
						<div className="data-box text-center">
							<p className="text-gray mb-0">目前未有任何交易</p>
						</div>
					</div>
					):(
						<div className="col-12 mt-2">
							<div className="data-box text-center">
								<p className="text-gray mb-0">目前未有任何交易</p>
							</div>
						</div>
					)}

				</div>
			</div>
		</div>
		</>
	);

};

export default MarketPage;