import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";
import Moment from 'moment';

import ProfileBanner from '../assets/img/profile-page-banner.jpg';

import CustomBtn from '../assets/img/custom-btn.png';

import SmallDataBox from '../assets/img/box-small.png';
import BigDataBox from '../assets/img/box-big.png';

// hooks
import { useWithdrawal } from '../hooks/useWithdrawal';

const ProfilePage = () => {

	const blockchain = useSelector((state) => state.blockchain);

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("复制成功");
	  setTimeout(() => setCopied(false), 3000);
	}


	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				setUserData(json[0]);
			} else {
				console.log("Invalid user data: More than 1 result.");
			}
		}
	};

	const [rcSellAmount, setRCSellAmount] = useState(0);
	const [balError, setBalError] = useState('');
	const [invalidAmount, setInvalidAmount] = useState(true);
	const handleAmountInput = (e) => {
	  	const value = e.target.value;
	  	console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidAmount(true);
			setBalError('请输入数字');
		} else if (e.target.value < 50) {
			setInvalidAmount(true);
			setBalError('最低提现数额: 50 RC');
		} else if (e.target.value % 10 != 0) {
			setInvalidAmount(true);
			setBalError('提现数额必须是 50 的倍数');
		} else if (userData.rc < e.target.value) {
			setInvalidAmount(true);
			setBalError('用户 RC 余额不足');
		} else {
			setInvalidAmount(false);
			setBalError('');
		}
		setRCSellAmount(e.target.value);
	};

	const { submitWithdraw, setIsWithdrawing, isWithdrawing, withdrawSuccess, setWithdrawSuccess } = useWithdrawal();

	const userWithdraw = async () => {
		await submitWithdraw(userData._id, blockchain.account, rcSellAmount, "Sell RC");
		setTimeout(() => fetchUserData(), 3000);
		setTimeout(() => fetchWithdrawData(), 4000);
		setTimeout(() => setRCSellAmount(0), 4000);
	};

	const [withdrawData, setWithdrawData] = useState(null);
	const fetchWithdrawData = async () => {
		const response = await fetch('https://api.ridechain.io/withdrawal/wallet/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			console.log("Withdrawal Records:", json);
			setWithdrawData(json);
		}
	};

	useEffect(() => {
		console.log("Account Page", blockchain.account);
		fetchUserData();
		fetchWithdrawData();
	}, []);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 px-0">
						<img className="w-100" src={ProfileBanner}></img>
					</div>
				</div>
			</div>

			<div className="main-content profile-bg">
				<div className="container pt-5">
					<div className="row">

						<div className="col-12 text-center">
							<h3 className="brand-blue">我的</h3>
						</div>

						<div className="col-12 text-center">
							<div className="data-container">
								<img className="w-100" src={BigDataBox}></img>
								<div className="data-text text-left">
									<p className="brand-yellow mb-0"><strong>我的钱包地址:</strong></p>
									<MiddleEllipsis><span className="text-white mt-1 mb-0" style={{fontSize:"14px"}}>{blockchain.account}</span></MiddleEllipsis>
									<p className="brand-yellow mb-0 mt-3"><strong>我的推荐人钱包地址:</strong></p>
									<MiddleEllipsis><span className="text-white mt-1 mb-0" style={{fontSize:"14px"}}>{userData && "0x..."+userData.referrer.substring(36)}</span></MiddleEllipsis>
								</div>
							</div>
						</div>

						<div className="col-12 mt-5 text-center">
							<h3 className="brand-blue">我的推荐链接</h3>
						</div>

						<div className="col-12 text-center">
						<MiddleEllipsis><span className="text-white mt-1 mb-0" style={{fontSize:"12px"}}>https://ridechain.io?referral={blockchain.account}</span></MiddleEllipsis>
							{/* <div className="data-container">
								<img className="w-100" src={SmallDataBox}></img>
								<div className="data-text text-left">
									<MiddleEllipsis><span className="text-white mt-1 mb-0" style={{fontSize:"12px"}}>https://ridechain.io?referral={blockchain.account}</span></MiddleEllipsis>
								</div>
							</div> */}
						</div>

						<div className="col-12 text-center mt-3">
							<CopyToClipboard 
							text={"https://ridechain.io?referral="+blockchain.account}
							onCopy={() => copyReferral()}>
								<button className="btn custom-btn" onClick={(e) => {
								}}>
									<img className="w-100" src={CustomBtn}></img>
									{!copied ? (
										<h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>复制链接</strong></h5>
									):(
										<h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>{copyText}</strong></h5>
									)}
								</button>
							</CopyToClipboard>
						</div>

						<div className="col-12 mt-5 text-center">
							<h3 className="brand-blue">收益详情</h3>
						</div>

						<div className="col-12 text-center">
							<div className="data-container">
								<img className="w-100" src={BigDataBox}></img>
								<div className="data-text text-left">

									<p className="brand-yellow mb-0"><strong>我的挖矿: <span className="text-white">{userData && userData.total_deposit} USDT</span></strong> </p>
									<p className="brand-yellow mt-2 mb-0"><strong>可得收益: <span className="text-white">{userData && userData.max_earning ? parseFloat(userData.max_earning).toFixed(2) : parseFloat(0).toFixed(2)}</span></strong> </p>
									<p className="brand-yellow mt-2 mb-0"><strong>挖矿总收益: <span className="text-white">{userData && userData.mining_profit ? parseFloat(userData.mining_profit*0.7).toFixed(2) : parseFloat(0).toFixed(2)}</span></strong> </p>
									<p className="brand-yellow mt-2 mb-0"><strong>团队总收益: <span className="text-white">{userData && userData.rankBonus ? parseFloat(userData.rankBonus*0.7).toFixed(2) : parseFloat(0).toFixed(2)}</span></strong> </p>
								</div>
							</div>
						</div>

						<div className="col-12 mt-5 text-center">
							<h3 className="brand-blue">提现收益</h3>
						</div>

						<div className="col-12 text-center">
							<div className="market-box">
								{isWithdrawing ? (
									<div className="w-100 text-center mt-3">
										<h5 className="brand-yellow"><strong>提现正在处理中</strong></h5>
										<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>请稍等。。。</strong></p>
									</div>
								):(
									<div className="w-100 text-center mt-3">
										{!withdrawSuccess ? (
											<>
												<p className="text-white mb-2" style={{fontSize:"16px"}}><span className="brand-yellow">RC余额:</span> {userData && parseFloat(userData.rc || 0).toFixed(2)}</p>
												<input
													type="number"
													placeholder='请输入 RC 提现数额'
													name='sellAmount'
													className='form-control text-center'
													onChange={handleAmountInput}
												/>
												{balError != "" ? <p className="error mt-2" style={{fontSize:"14px"}}>{balError}</p> : <></>}
												<p className="error mt-5 mb-0" style={{fontSize:"24px"}}><strong>-{parseFloat(rcSellAmount || 0).toFixed(2)} <span className="text-white">RC</span></strong></p>
												<div className="w-100 text-center my-3">
													<p className="brand-blue mb-0" style={{fontSize:"24px"}}><i className="fa-solid fa-circle-arrow-down"></i></p>
												</div>
												<p className="text-white mb-0" style={{fontSize:"12px"}}>提现手续费: <span className="brand-yellow">5%</span></p>
												<p className="profit-green mt-1" style={{fontSize:"24px"}}><strong>{parseFloat(rcSellAmount-(rcSellAmount*5/100) || 0).toFixed(2)} <span className="text-white">USDT</span></strong></p>
												<button disabled={invalidAmount || balError != ""} className="btn custom-btn mt-3" data-dismiss="modal" onClick={(e) => {
													setIsWithdrawing(true);
													userWithdraw();
												}}>
													<img className="w-100" src={CustomBtn}></img>
													<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>确认提现</strong></h5>
												</button>
											</>
										):(
											<>
												<h5 className="profit-green"><strong>提现成功!</strong></h5>
												<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>您所提现的 USDT 将会在2小时天内到账您的钱包，请检查提现记录。</strong></p>
												<button className="btn custom-btn mt-3" data-dismiss="modal" onClick={(e) => {
													setWithdrawSuccess(false);
												}}>
													<img className="w-100" src={CustomBtn}></img>
													<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>完成</strong></h5>
												</button>
											</>
										)}
									</div>
								)}
							</div>
						</div>

						<div className="col-12 mt-5 text-center">
							<h3 className="brand-blue">我的提现记录</h3>
						</div>

						<div className="col-12">
							{withdrawData && withdrawData.length > 0 ? (
								<>
									{withdrawData && withdrawData.map((withdrawalRecord, index) => (
										<div className="data-box mt-2" key={index}>
											<p className="text-white mb-0" style={{fontSize:"14px"}}>{Moment(withdrawalRecord.createdAt).format('YYYY/MM/DD')} <span className="profit-green"><strong>+{parseFloat(withdrawalRecord.usdt_amount).toFixed(2)} USDT</strong></span></p>
											{withdrawalRecord.status == "Processing" ? <p className="brand-yellow mb-0" style={{fontSize:"14px"}}>正在处理中</p> : <></>}
											{withdrawalRecord.status == "Completed" ? <p className="profit-green mb-0" style={{fontSize:"14px"}}>提现已到账</p> : <></>}
										</div>
									))}
								</>
							):(
								<div className="col-12 mt-2">
									<div className="data-box">
										<div className="row">
											<div className="col-12 text-center">
												<p className="text-white mb-0">此用户没有提现记录。</p>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);

};

export default ProfilePage;